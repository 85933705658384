import { Footer } from "../Footer/Footer";
import { TermsAndConditions } from "./TermsAndConditions";

export const TC_PP = () => {
    return (
        <>
            <TermsAndConditions/>
            <Footer/>
        </>
    );
}