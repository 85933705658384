import "./BackgroundImageTraining.css";

export const BackgroundImageTraining = ({data}) => {

    const backgroundStyle = {
        "backgroundImage": `linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)), url(${data.backgroundImage})`
    };

    console.log(backgroundStyle)

    return (
        <div>
            <div id="trainingBackgroundImage" className="row justify-content-center align-items-center" style={backgroundStyle}>
                {data.backgroundTitle}
            </div>
        </div>
    );
}