const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animate-from-left"); // Add your CSS class here
      } else {
        entry.target.classList.remove("animate-from-left"); // Remove your CSS class here
      }
    });
  });
  
  export default observer;