import { useRef, useEffect } from "react";
import observer from "../Hooks/scrollObserver";
import "./Offer.css"

export const Offer = () => {
    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current

        return () => {
            observer.unobserve(cur);
        };
    }, []);

    return (
        <section ref={myRef} className="containerBackgroundQuestion" id="offer">
            <div className="bigTitle">Zakaj trenirati ravno z mano?</div>
            <div className="col-lg-8 col-sm-12 col-md-10 mx-auto">
                <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 text-center">
                    <Feature
                        title="Individualen pristop"
                        subtitle="PERSONALIZIRAN TRENING PROGRAM"
                        text='
                            Najprej izvedeva brezplačni uvodni trening, kjer narediva začetne meritve ter se pogovoriva
                            o vaših ciljih in pričakovanjih.

                            Nato sestavim trening program, ki zagotavlja najboljšo
                            možno podlago za doseganje vaših fitnes ciljev.
                        '/>

                    <Feature
                        title="Disciplina "
                        subtitle="VSAK DAN 1% BOLJŠA"
                        text='
                            Sem velik zagovornik discipline pred motivacijo, ker vem, da se le tako lahko doseže vrhunske
                            rezultate. S treningom ne bova izboljšala le fizičnega počutja ampak bova utrdila tudi
                            mentalnega, tako da bo trening komplementiral vaša vsakdanja opravila in delo.
                        '/>

                    <Feature
                        title="Lorem ipsum"
                        subtitle="Lorem ipsum"
                        text='
                            Lorem ipsum
                        '/>

                    <Feature
                        title="Merljiv napredek"
                        subtitle="ANALIZA REZULTATOV"
                        text='
                            V sklopu individualnih treningov dobite tudi tabelo za merjenje napredka, kamor bova skrbno
                            vpisovala vaše dosežke na treningih, telesne mere in druge pokazatelje napredka. Z analizo
                            rezultatov bova dosegla vaše cilje na najhitrejši možen način.
                        '/>

                </div>
            </div>
        </section>
    )
}

const Feature = (props) => {
    return (
    <div className="row justify-content-center m-0 p-lg-4 p-2">
        <div className="col-lg-4 col-md-12 col-sm-12 offerImage row align-content-center justify-content-center">
            <img src="/media/image3.png" alt="image3"/>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row">
                <div className="col-sm-12">
                    <div className="font title p-2">
                        {props.title}   
                    </div>
                    <div className="font subtitle p-2">
                        {props.subtitle}
                    </div>
                    <div className="text p-2">
                        {props.text}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}