import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Navbar } from './Navbar/Navbar';
import { Landing } from "./Landing/Landing";
import { Contact } from "./Contact/Contact";
import { ParentTraining } from "./Trainings/ParentTraining";

import trainingData from './Content/Training_Options.json';
import { TC_PP } from "./TC_PP/TC_PP";

const App = () => {
  return (
      <BrowserRouter>
         <Routes>
             <Route path="/" element={<Navbar />}>
                 <Route index element={<Landing />} />
                 <Route path="kontakt" element={<Contact />} />
                 {
                     trainingData.map(data => (
                        <Route path={data.link} element={<ParentTraining data={data}/>}/>
                     ))
                 }
                 <Route path="splosni-pogoji" element={<TC_PP/>}/>
             </Route>
         </Routes>
     </BrowserRouter>
  );
}

export default App;
