import { About } from './About';
import { Inspirational } from './Inspirational';
import { Offer } from './Offer';
import { Testemonial } from './Testemonial';
import { Footer } from '../Footer/Footer';

export const Landing = () => {
    return (
        <div className="container-fluid g-0">
          <Inspirational/>
          <About/>
          <Offer/>
          <Testemonial/>
          <Footer/>
        </div>
    );
}