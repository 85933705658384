import './Navbar.css';
import { Outlet, Link } from "react-router-dom";
import { useState } from "react";
import trainingData from '../Content/Training_Options.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export const Navbar = () => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [IsMobileVisible, setIsMobileVisible] = useState(false);

    const hideNavbarOnClick = () => {
        setIsMobileVisible(false);
        setIsDropdownVisible(false)
    }

    const dropdownContent = (
        <div className={IsMobileVisible? "navelement dropdown fadeIn": "navelement dropdown fadeOut"}>
            <div className="dropdown-wrapper">
                <ul>
                    {trainingData.map(plan => (
                        <li key={plan.link}>
                            <Link to={plan.link} onClick={()=> hideNavbarOnClick()}>
                                <div>{plan.nav_element}</div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );

    return (
        <section>

            <nav className='navbar'>
                {/* Desktop Navbar */}
                <div id="navbarLogo">
                    <img src="/media/logos/logo.png" alt="pika-coaching-logo" />
                </div>
                <div id="navbarLinks">
                    <div className="navElement">
                        <Link to='/#' className="navLink">
                            Domov
                        </Link>
                    </div>
                    <div id="navDropdown">
                        <div className="navElement" onMouseEnter={() => setIsDropdownVisible(true)} onMouseLeave={() => setIsDropdownVisible(false)}>
                            <div className="navLink">Treningi</div>
                            {isDropdownVisible && dropdownContent}
                        </div>
                    </div>
                    <div className="navElement">
                        <Link to='/kontakt' className="button-4" id="navPrijava">Prijava na uvodni trening</Link>
                    </div>
                </div>
            </nav>

            <nav className='navbar-mobile'>
                {/* Mobile Navbar with Burger Menu */}
                <div id="navbarLogo">
                    <img src="/media/logos/logo.png" alt="pika-coaching-logo" />
                </div>
                <div id="mobileMenu" onClick={() => setIsMobileVisible(!IsMobileVisible)}>
                    <FontAwesomeIcon icon={ faBars } size='2x'/>
                </div>
                {IsMobileVisible && (
                    <div id="mobileMenuContent" className={IsMobileVisible? "fadeIn": "fadeOut"}>
                        <div className="navElement">
                            <Link to='/#' className="navLink" onClick={hideNavbarOnClick}>
                                Domov
                            </Link>
                        </div>
                        <div className="navElement" onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                            <div className="navLink">Treningi</div>
                        </div>
                        {isDropdownVisible && dropdownContent}
                        <div className="navElement">
                            <Link to='/kontakt' className="button-4" id="navPrijava" onClick={hideNavbarOnClick}>Prijava na uvodni trening</Link>
                        </div>
                    </div>
                )}
            </nav>
            <Outlet />
        </section>
    );
}