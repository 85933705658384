import { Footer } from "../Footer/Footer";
import { Training } from "./Training";
import { BackgroundImageTraining } from "./BackgroundImageTraining";

export const ParentTraining = ({data}) => {
    const backgroundData = {
        "backgroundImage": data.background_image,
        "backgroundTitle": data.background_title
    }
    return (
        <section className="container-fluid g-0 text-center" id="trainingSection">
            <BackgroundImageTraining data={backgroundData}/>
            <Training data={data}/>
            <Footer/>
        </section>
    );
}