import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import observer from "../Hooks/scrollObserver";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
export const Footer = () => {

    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current;

        return () => {
            observer.unobserve(cur);
        };
    }, []);

    return (
        <section ref={myRef} className="footerBackground" id="footer">
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-6 col-xl-3">
                        <p className="m-0 footerElement" id="logoName">Pika</p>
                        <p className="m-0 footerElement" id="logoCoaching">Coaching</p>
                        <div className="m-0" id="footerIconLinks">
                            <a href="https://www.facebook.com/pika.miskulin">
                                <FontAwesomeIcon icon={faFacebook} size="2x"/>
                            </a>
                            <a href="https://www.instagram.com/pikamiskulin/">
                                <FontAwesomeIcon icon={faInstagram} size="2x"/>
                            </a>
                            <a href="https://www.tiktok.com/@pikacoaching.com">
                                <FontAwesomeIcon icon={faTiktok} size="2x"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-6 col-xl-9 row align-items-center justify-content-center">
                        <div className="col-xl-4 col-12" id="footerContact">
                            <p className="footerElement" id="footerContactTitle">Kontakt</p>
                            <p className="footerElement" id="footerContactInfo">
                                <a href="mailto:pikacoaching@gmail.com">pikacoaching@gmail.com</a>
                                <br />
                                +386 41 490 490
                            </p>
                            </div>
                        <div className="col-xl-4 col-12 row justify-content-center">
                            <Link to="/splosni-pogoji" className="footerElement footerLinkElement p-0" id="footerTC">Splošni pogoji</Link>
                            <p>© Copyright 2024. All right reserved.</p>
                        </div>
                        <div className="col-xl-4 col-12" id="footerLinks">
                            <Link to="/" className="footerElement footerLinkElement" >Domov</Link>
                            <Link to="/kontakt" className="footerElement footerLinkElement" >Prijavi se na trening</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
