import { useEffect, useRef } from "react";
import observer from "../Hooks/scrollObserver";
import "./Training.css"

export const Training = ({data}) => {
    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current;

        return () => {
            observer.unobserve(cur);
        };
    }, []);

    return (
        <section ref={myRef}>
            <div className="justify-content-center" id="trainingPage">
                <div className="aboutIndTrainingElement " id="aboutTrainingRoot">
                    <div className="py-4 row justify-content-center align-items-center" id="aboutTrainingBackground">
                        <div className="col-xxl-5 col-xl-7 col-10">
                            <div id="line"></div>
                            <h2 id="ListTitle">{data.list_title}</h2>
                            <div>
                                <p id="trainingText">
                                    {data.training_text}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="trainingReason">
                    <div id="trainingFlex" className="row justify-content-center align-items-center">
                        <List data={data.program}/>
                        <Cenik data={data.cenik}/>
                    </div>
                    <TrainingFooter data={data.dodatek}/>
                </div>

            </div>
        </section>
    );
    /*
     <div className="col-5">
         <div className="col-5 row justify-Content-center" id="trainingImage">
             <img src="/media/pika2.png" alt="image1"/>
         </div>
     </div>
     */
}

const List = ({data}) => {
    return (
        <div className="aboutIndTrainingElement py-4 col-xxl-4 col-xl-4 col-10" id="trainingReasonList">
            <h3 className="py-4">Kaj ponujam?</h3>
            {
                data.map((item, index) => <p key={index}>{item}</p>)
            }
        </div>
    );
}

const Cenik = ({data}) => {
    return (
        <div className="col-xxl-4 col-xl-4 col-10">
            <div id="trainingCenik">
                <h5>Cenik:</h5>
                <div>
                    {
                        data.map((item, index) => <p key={index}>{item}</p>)
                    }
                </div>
            </div>
        </div>
    );
}

const TrainingFooter = ({data}) => {
    return (
        <div className="py-5">
            {
                data.map((item, index) => <p className="aboutIndTrainingApendix m-0" key={index}>{item}</p>)
            }
        </div>
    );
}