 import {useEffect, useRef, useState} from "react";
 import emailjs from 'emailjs-com';
 import "./Form.css"
 import observer from "../Hooks/scrollObserver";


export const Form  = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [age, setAge] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(e);
        emailjs.sendForm('service_rwzxtrh', 'template_r46y8b3', e.target, 'ithCPqCE124qEYV7o')
            .then((result) => {
                window.location.reload()
            }, (error) => {
                console.log(error.text);
            });
    }

    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current;

        return () => {
            observer.unobserve(cur);
        };
    }, []);

    return (
        <div id="contactForm" className="row justify-content-center" ref={myRef}>
            <h3 className="pt-4" id="formTitle">
                Že prijava na uvodni trening vas naredi 1% boljše kot včeraj.
            </h3>

            <form onSubmit={sendEmail} className="col-12 col-lg-5 col-xl-4 col-xxl-5 row align-items-center justify-content-center py-4">

                <div className="form-group col-md-6 col-sm-12 py-2">
                    <label htmlFor="formName">Ime</label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} name="formName" className="form-control"/>
                </div>

                <div className="form-group col-md-6 col-sm-12 py-2">
                    <label htmlFor="formSurname">Priimek</label>
                    <input type="text" value={surname} onChange={(e) => setSurname(e.target.value)} name="formSurname" className="form-control"/>
                </div>

                <div className="form-group col-md-6 col-sm-12 py-2">
                    <label htmlFor="formAge">Starost</label>
                    <input type="number" value={age} onChange={(e) => setAge(e.target.value)} name="formAge" className="form-control"/>
                </div>

                <div className="form-group col-md-6 col-sm-12 py-2">
                    <label htmlFor="formEmail">Email </label>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="formEmail" className="form-control"/>
                </div>

                <div className="form-group col-12 py-2">
                    <label>
                        Katera vrsta treninga vas zanima?
                    </label>
                    <select className="form-control" name="formTraining">
                        <option>Individualni trening</option>
                        <option>Trening v paru</option>
                        <option>Online individualni trening</option>
                        <option>Online mentorstvo</option>
                        <option>Vodene vadbe</option>
                    </select>
                </div>

                <div className="form-group col-12 py-2">
                    <label htmlFor="formMessage">Sporočilo</label>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} id="formMessage" name="formMessage" className="form-control"/>
                </div>

                <div className="form-group col-12 py-2">
                    <input type="submit" value="Pošilji" className="button-4 w-100"/>
                </div>

            </form>

            
        </div>
    );
}