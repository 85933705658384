import { useRef, useEffect } from "react";
import observer from "../Hooks/scrollObserver";
import "./About.css";

export const About = () => {
    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current

        return () => {
            observer.unobserve(cur);
        };
    }, []);

    return (
        <section ref={myRef} id="about">
            <div className="row g-0 align-items-center justify-content-center">
                <Quote />
                <Image />
            </div>
        </section>
    );
};

const Image = () => {
    return (
        <div className="col-5" id="aboutImage">
            <img src="/media/pika2.png" alt="image1" />
        </div>
    );
};

const Quote = () => {
    return (
        <div className="col-lg-5 col-12 row justify-content-center text-center">
                <p className="col-10 my-2 mx-0 aboutElement" id="aboutTitle">
                    Kdo sem?
                </p>
                <p className="col-10 my-2 mx-0 aboutElement" id="aboutMe">
                    Moje ime je Pika Miškulin, sem certificirana osebna trenerka in študentka kineziologije na Fakulteti
                    za šport. V športu sem že od malih nog, največ časa sem se ukvarjala z akrobatskim rokenrolom, ki mi
                    je prinesel že kar nekaj izkušenj z različnimi tipi treninga. Nato sem svojo pot nadaljevala v fitnesu,
                    sedaj pa me zanimajo tudi športi na prostem kot so tek, plavanje, kalistenika in podobno. 
                </p>
        </div>
    )

};
