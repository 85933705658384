import "./BackgroundImage.css";
import {useEffect, useRef} from "react";
import observer from "../Hooks/scrollObserver";

export const BackgroundImage = () => {

    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current;

        return () => {
            observer.unobserve(cur);
        };
    }, []);

    return (
        <div ref={myRef}>
            <div id="contactImage" className="row justify-content-center align-items-center">
                KONTAKT
            </div>
        </div>
    );
}