import { useEffect, useRef } from "react";
import observer from "../Hooks/scrollObserver";
import "./Testemonial.css";
import testemonialData from '../Content/Testemonials.json';

export const Testemonial = () => {

    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current

        return () => {
            observer.unobserve(cur);
        };
    }, []);

    return (
        <section ref={myRef} className="containerBackgroundTestemonial" id="testemonial">
            <div className="bigTitle">Priporočila</div>
            <div className="gallery-wrapper pb-4 pb-lg-4">
                <div id="gallery-track">
                    {
                        testemonialData.map(testemonial => (
                            <Card title={testemonial.title} text={testemonial.text} picture={testemonial.picture} key={testemonial.title} />
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

const Card = (props) => {
    return (
        <div className="row m-0 testemonialCard py-lg-4 py-3">
            <div className="col-12 cardWrapper row justify-items-center">
                <div className="col-12 cardImage py-lg-4 py-2 m-0">
                    <img src={props.picture} alt="testemonialImage"/>
                </div>
                <div className="col-12 font titleTestamonial px-2">
                    {props.title}
                </div>
                <div className="col-11 text p-2">
                    {props.text}
                </div>
            </div>
        </div>
    );
};
