import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import observer from "../Hooks/scrollObserver";
import "./Inspirational.css"

export const Inspirational = () => {

    const myRef = useRef();

    useEffect(() => {
        observer.observe(myRef.current);
        let cur = myRef.current

        return () => {
            observer.unobserve(cur);
        };
    }, []);    

    return (
        <section ref={myRef} id="inspirational">
            <div className="row h-100 g-0 align-items-center justify-content-center" id="inspirationalDiv">
                <div className="col-12 col-lg-5 row justify-content-center">
                    <p className="col-10 my-2 mx-0 text-center inspirationalElement" id="inspirationalQuote">
                        SPREMENI SVOJE ŽIVLJENJE NA BOLJŠE…
                    </p>
                    <Button />
                </div>
            </div>
        </section>
    )
}

const Button = () => {
    return (
        <Link to="/kontakt" className="col-10 col-lg-8 my-2 mx-0 text-center align-middle inspirationalElement button-4" id="inspirationalButton">
            Prijava na uvodni trening
        </Link>
    )
}