import {Form} from "./Form";
import {BackgroundImage} from "./BackgroundImage";
import {Footer} from "../Footer/Footer";

export const Contact = () => {
    return (
        <section className="container-fluid g-0 text-center">
            <BackgroundImage/>
            <Form/>
            <Footer/>
        </section>
    );
}