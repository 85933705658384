import "./TermsAndConditions.css"

export const TermsAndConditions = () => {

    return (
        <section id="TCSection">
            <div id="TCContainer">
                <h3>1.UVOD</h3>
                <p>
                    Splošni pogoji veljajo za vsakega uporabnika (v nadaljevanju: uporabnik), ki obišče oziroma uporablja spletno mesto pikacoaching,
                    ki je v času sprejetja teh splošnih pogojev dostopno na spletnem naslovu https://pikacoaching.com (v nadaljevanju: spletno mesto).
                    Lastnik spletnega mesta je Pikacoaching, Pika Miškulin (v nadaljevanju: lastnik).
                </p>
                <h3>2. UPORABA SPLETNEGA MESTA</h3>
                <p>
                    Uporabnik se zavezuje, da bo spletno mesto uporabljal zakonito in na običajen način, v skladu z namenom spletnega mesta. Če uporabnik
                    spletno mesto uporablja v nasprotju s prejšnjim odstavkom, mu lahko lastnik ali avtor začasno ali trajno (glede na težo kršitve) onemogoči
                    dostop do vsebin. Z uporabo spletnega mesta med uporabnikom in lastnikom ne nastane nobena pogodba in noben pogodben odnos. Uporabnik
                    zaradi uporabe spletnega mesta nima nobenih drugih obveznosti. Lastnik si pridržuje pravico, da dostop do določenih delov spletne
                    strani ali posameznih funkcionalnosti kadarkoli omeji (npr. samo za registrirane uporabnike ali naročnike) ali veže na izpolnitev določenih pogojev.
                    Uporaba spletnega mesta je prav tako na lastno odgovornost.
                </p>
                <h3>3. IZKLJUČITEV ODGOVORNOSTI</h3>
                <p>
                    Lastnik ne jamči, da so vse vsebine, objavljene na spletnem mestu, točne, popolne in pravilne in ne odgovarja za morebitno škodo, ki bi
                    nastala uporabniku, ker se je zanesel na objavljene vsebine.
                    Lastnik ne prevzema nobene odgovornosti glede delovanja ali dostopnosti spletnega mesta in ne odgovarja za morebitno škodo, ki bi nastala
                    uporabniku, ker spletno mesto ni bilo dostopno ali ni delovalo ali ni delovalo pravilno.
                    Lastnik ne odgovarja za škodo, ki bi zaradi uporabe spletnega mesta utegnila nastati na uporabnikovi strojni, programski ali drugi opremi.
                    Uporabnik je dolžan sam poskrbeti za primerno zaščito (protivirusno ipd.) opreme, s pomočjo katere dostopa do spletnega mesta.
                </p>
                <h3>4. VARSTVO PRAVIC INTELEKTUALNE LASTNINE</h3>
                <p>
                    Celotna vsebina spletnega mesta, ne glede na obliko, v kateri je izražena, je avtorsko pravno zaščitena. Nosilec avtorskih pravic na
                    objavljenih vsebinah je lastnik oz. avtor bloga oziroma oseba, za katero je to pri posamezni vsebini to izrecno navedeno.
                    Vse avtorske pravice so pridržane njihovim nosilcem. Objave vsebin na spletnem mestu v nobenem primeru ni mogoče razlagati kot odpoved
                    katerikoli avtorski pravici na vsebinah spletnega mesta.
                    Brez izrecnega vnaprejšnjega pisnega dovoljenja lastnika, avtorice oziroma nosilca avtorskih pravic je prepovedana kakršnakoli uporaba
                    katerekoli vsebine, objavljene na spletnem mestu, za kakršenkoli namen, razen za oseben ogled uporabnika. Prav tako je prepovedana uporaba
                    vsebine na način, da se celotno spletno mesto vključi v drugo spletno mesto. Kršitev ima lahko za posledico kazensko in odškodninsko odgovornost.
                </p>
                <h3>5. PRENEHANJE STORITEV</h3>
                <p>
                    Storitve svetovanja se lahko prekinejo zaradi neizpolnjevanja pogojev dogovora ali kršitve pravil spletnega mesta.
                    Lastnik si pridržuje pravico preklica storitev ob neprimernem vedenju stranke ali kršitvi dogovora.
                </p>
            </div>
        </section>
    );
};